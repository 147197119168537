import React, { useState } from 'react';
import '@hackplan/uui/lib/index.css';
import { Button, TextField, Toaster, ToasterPosition, Dialog, Table } from '@hackplan/uui';

import logo from './logo.svg';
import eduLogo from './eduLogo.svg';
import './App.css';
import SHA256 from 'crypto-js/sha256';
import AES from 'crypto-js/aes'
import cryptoHex from 'crypto-js/enc-hex'
import cryptoUtf8 from 'crypto-js/enc-utf8';
import axios from 'axios';
console.error("「设计不是我做的，甲方要求的，我尽力了···」")
console.log("最初设计原型&领导指导过程： https://www.figma.com/file/34NkadbUEfoE4sMDF8pnSu/%E9%AB%98%E8%80%83%E6%9F%A5%E8%A9%A2?node-id=0%3A1")

console.log("前端使用 React https://reactjs.org/ 与 UUI https://uui.cool/ 完成")
console.log("Develop by RemiIO <i@linux.dog>")


const AppToaster = Toaster.create({
  maxToasts: 5,
  position: ToasterPosition.TopRight,
})


const App = props => {
  const [name, setName] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const [lastSixId, setLastSixId] = useState("");

  const [buttonLoading, setButtonLoading] = useState(false);
  const [resultDialog, setResultDialog] = useState(false);
  const [resultArr, setResultArr] = useState([]);

  //const [results, setResults] = useState({});
  const submitForm = () => {
    const queryKey = SHA256(`${name}-${candidateId}`).toString(cryptoHex) + candidateId
    const decryptionKey = SHA256(`${name}-${lastSixId.toUpperCase()}`).toString(cryptoHex)
    setButtonLoading(true)

    axios.post(`https://api.hljedu.gov.cn/api/transcript`, {
      key: queryKey
    }).then((response) => {
      if (response.data && response.data.secret) {
        try {
          let resultObj = JSON.parse(AES.decrypt(response.data.secret, decryptionKey).toString(cryptoUtf8))
          delete resultObj.考生号
          delete resultObj.姓名
          delete resultObj.身份证后六位
          setResultArr(Object.entries(resultObj))
          setResultDialog(true)
        } catch (error) {
          AppToaster.show({
            customize: {
              Root: {
                extendClassName: "msg-toast",
                extendStyle: { padding: 12, },
              }
            },
            message: '没有查询到您的成绩，请确认您的信息。',
          })
        }
      }

    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          AppToaster.show({
            customize: {
              Root: {
                extendClassName: "msg-toast",
                extendStyle: { padding: 12, },
              }
            },
            message: '没有查询到您的成绩，请确认您的信息。',
          })
        }
      } else if (error.request) {
        alert(error.request)
        AppToaster.show({
          customize: {
            Root: {
              extendClassName: "msg-toast",
              extendStyle: { padding: 12, },
            }
          },
          message: '网络错误，请检查网络',
        })
      } else {
        console.log('Error', error.message);
      }
    }).then(() => {
      setButtonLoading(false)
    })
  }
  let inputStatus = name.length === 0 || candidateId.length === 0 || lastSixId.length !== 6
  return (
    <div className="App">
      <Dialog open={resultDialog} focusTrap={true} onClickAway={() => { }}>
        <div style={{
        }}>
          <h1 className="dialog-title">您的高考成绩</h1>
        </div>
        <div className="dialog-info">
          <p className="dialog-p">姓名：{name} </p>
          <p className="dialog-p">考号：{candidateId}</p>
        </div>
        <Table columns={[{ title: "科目" }, { title: "成绩" }]} rows={resultArr} style={{
          "lineHeight": "0.8em"
        }}></Table>
        <div className="dialog-info-left">
          <p>注：2020年高考外语听力成绩不计入高考总分。</p>
        </div>
        <div className="dialog-buttons">
          <Button className="" onClick={() => {
            setResultDialog(value => {
              return !value;
            });
          }}>
            关闭窗口
              </Button>
        </div>
      </Dialog>
      <div className="part-title">
        <img className="title" src={logo} alt={"2020黑龙江省普通高等学校招生全国统一考试"} />
      </div>
      <div className="part-form">
        <div className="max-width-in-pc">
          <div className="part-r-buttom">
            <div className="form">

              <TextField className="form-input" type='text' value={name} showLengthIndicator onChange={value => {
                setName(value);
              }} placeholder={'姓名'} />
              <TextField className="form-input" type='text' value={candidateId} showLengthIndicator onChange={value => {
                setCandidateId(value);
              }} placeholder={'考号'} />
              <TextField className="form-input" maxLength="6" type='text' value={lastSixId} showLengthIndicator onChange={value => {
                setLastSixId(value);
              }} placeholder={'身份证号（后六位）'} />
              <div className="align-right">
                <Button className="query-button" loading={buttonLoading} onClick={submitForm} disabled={
                  inputStatus
                }>{inputStatus ? `请输入信息查询成绩` : `查询成绩`}</Button>
              </div>

            </div>
            <div className="mobile-logo">
              <img src={logo} alt={"2020黑龙江省普通高等学校招生全国统一考试"} />
            </div>
          </div>
        </div>
      </div>
      <div className="part-footer">
        <div className="footer font-color-gray">
          <img src={eduLogo} alt={"2020黑龙江省普通高等学校招生全国统一考试"} />
        </div>
      </div>
    </div >
  );
}

export default App;
